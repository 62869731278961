import Head from "next/head";
import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/reducers";
import { BrandState } from "../../redux/reducers/brand.reducer";
import { brandConfigSelector } from "../../redux/selectors/app.selectors";
import { BrandConfigKeys } from "../../utils/enums";

interface GenericSetHeadProps {
  title: string;
  siteName?: string;
  metadata?: Array<{
    property: string;
    content: string;
  }>;
  keyword?: string;
}

const GenericSetHead: React.FC<GenericSetHeadProps> = (props) => {
  const { title, siteName, metadata, keyword } = props;
  const { configEntities: brandConfigs, pwa_data }: BrandState = useSelector((app: AppState) => app.brand)
  const brandPWAConfig = brandConfigSelector(
    brandConfigs,
    BrandConfigKeys.PWA_CONFIG
  )?.value
  const appleTouchIcon = (pwa_data?.manifest_data?.icons || []).find((icon: any) => icon.sizes === '180x180')?.src || ''
  return (
    <Head>
      <title>{title}</title>
      <meta name="title" content={`${title}`} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={`${title}`} />
      <meta property="og:title" content={`${title}`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteName || title} />

      {brandPWAConfig?.is_enabled && brandPWAConfig?.manifest_url &&
        <>
          <link rel="manifest" href={brandPWAConfig?.manifest_url || ''} />
          <link
            rel='apple-touch-icon'
            sizes='180x180'
            href={appleTouchIcon}
          />
        </>}
      <meta name='msapplication-TileColor' content={pwa_data?.manifest_data?.theme_color} />

      {(metadata || []).map((meta, index) => {
        if (meta.property === "description") {
          return (
            <React.Fragment key={index}>
              <meta property={`${meta.property}`} content={meta.content} />
            </React.Fragment>
          );
        }
        return null;
      })}
      {(metadata || []).map((meta, index) => {
        if (
          meta.property === "description" ||
          meta.property === "image" ||
          meta.property === "url"
        ) {
          return (
            <React.Fragment key={index}>
              <meta property={`og:${meta.property}`} content={meta.content} />
              <meta
                property={`twitter:${meta.property}`}
                content={meta.content}
              />
            </React.Fragment>
          );
        }
        return null;
      })}
      {(metadata || []).map((meta, index) => {
        if (meta.property === "icon") {
          return (
            <React.Fragment key={index}>
              <link rel={`${meta.property}`} href={meta.content} />
            </React.Fragment>
          );
        }
        return null;
      })}
    </Head>
  );
};

export default GenericSetHead;
